@import 'styles/theme.scss';

.itemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 924px;
  min-height: 234px;
  font-weight: $font-weight-700;
  font-size: 20px;
  line-height: 1.2;
  color: $primary-dark;
  background-color: $primary-100;
  text-align: left;

  position: relative;
  padding: 0 72px;
  border-radius: 12px;
  // margin: $spacing * 13 auto;
  blockquote {
    margin-right: 0;
    margin-left: 0;
  }

  .beforeQuote {
    width: 60px;
    height: 50px;
    color: $primary-dark;
    position: absolute;
    left: 56px;
    top: -30px;
    @media (max-width: $md) {
      width: 24px;
      height: 22px;
      left: 20px;
      top: -10px;
    }
  }

  .afterQuote {
    width: 60px;
    height: 50px;
    color: $primary-dark;
    position: absolute;
    right: 56px;
    bottom: -30px;
    @media (max-width: $md) {
      width: 24px;
      height: 22px;
      right: 20px;
      bottom: -9px;
    }
  }

  @media (max-width: $md) {
    font-weight: $font-weight-500;
    font-size: 16px;
    line-height: 20px;
    padding: 0 24px;
  }
}
.name {
  font-weight: $font-weight-400;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: $md) {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
}
.none {
  background-color: $white;
}
.blueBox {
  background-color: $primary-100;
}

.heading {
  font-weight: $font-weight-700;
  color: $primary-dark;
  font-size: 34px;
  line-height: 44px;
  margin-bottom: 30px;
}

.slides_show {
  margin-left: 30px;
}

.quote {
  p {
    font-weight: $font-weight-700;
    font-size: 20px;
    line-height: 1.2;
    color: $primary-dark;
    margin: 0;
    padding-bottom: 10px;
  }
}
